import React from 'react'
import Header from '../../components/Header/Header'
import Footer from './../../components/Footer/Footer'
import FullBody1 from './../../components/Classes/FullBody1/FullBody1'

const FullBody1Class = () => {
  return <>
    <Header />
    <FullBody1 />
    <Footer />
  </>
}

export default FullBody1Class