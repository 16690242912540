import TimeTable from "../../components/TimeTable/TimeTable"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"

const ReserveLesson = () => {
  return <>
    <Header />
    <TimeTable />
    <Footer style={{ position: 'absolute', bottom: '0', height: '8vw', width: '100%' }}/>
  </>
}

export default ReserveLesson