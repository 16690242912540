import styles from './ClassesWrapper.module.scss'
// import breathe from 'https://ibb.co/nDpSzpn'
// import stretching from 'https://ibb.co/NyV9D12'
import { Link } from 'react-router-dom';

const ClassesWrapper = () => {
  const stretch1 = 'https://i.ibb.co/71YnMgt/stretching.jpg'
  const breathe = 'https://i.ibb.co/YtgGpgy/breathe.jpg'
  const yoga = 'https://i.ibb.co/9cyNFt6/photo-2024-04-03-19-21-16.jpg'
  const fullBody1 = 'https://i.ibb.co/SJnwbHN/photo-2024-04-03-19-21-15.jpg'
  const fullBody2 = 'https://i.ibb.co/W6pbbgh/photo-2024-04-03-20-13-54.jpg'
  const stretch2 = 'https://i.ibb.co/12136Nx/photo-2024-04-03-19-25-09.jpg'
  const booty1 ="https://i.ibb.co/hfHz8zq/photo-2024-04-03-22-13-33.jpg"

  return <div className={styles.bookClassesWrapper}>
    <div className={styles.classWrapper}>
        <img
            src={breathe} // Используйте импортированное изображение или URL
            alt="breathe"
            className={styles.previewImage} // Вы можете сохранить этот стиль или изменить его
        />
        <button className={styles.previewButton}>
          <Link to="/breathe">Buy</Link>
          {/* buy */}
        </button> 
    </div> 
    <div className={styles.classWrapper}>
        <img
            src={stretch1} // Используйте импортированное изображение или URL
            alt="stretch1"
            className={styles.previewImage} // Вы можете сохранить этот стиль или изменить его
        />
        <button className={styles.previewButton}>
          <Link to="/stretch1">Buy</Link> 
          {/* buy */}
        </button>
    </div>
    <div className={styles.classWrapper}>
        <img
            src={yoga} // Используйте импортированное изображение или URL
            alt="yoga"
            className={styles.previewImage} // Вы можете сохранить этот стиль или изменить его
        />
        <button className={styles.previewButton}>
          <Link to="/yoga1">Free</Link>
          {/* buy */}
        </button> 
    </div>
    <div className={styles.classWrapper}>
        <img
            src={fullBody1} // Используйте импортированное изображение или URL
            alt="fullBody1"
            className={styles.previewImage} // Вы можете сохранить этот стиль или изменить его
        />
        <button className={styles.previewButton}>
          <Link to="/fullBody1">Free</Link>
          {/* buy */}
        </button> 
    </div>
    <div className={styles.classWrapper}>
        <img
            src={fullBody2} // Используйте импортированное изображение или URL
            alt="fullBody2"
            className={styles.previewImage} // Вы можете сохранить этот стиль или изменить его
        />
        <button className={styles.previewButton}>
          <Link to="/fullBody2">Free</Link>
          {/* buy */}
        </button> 
    </div>
    <div className={styles.classWrapper}>
        <img
            src={stretch2} // Используйте импортированное изображение или URL
            alt="stretch2"
            className={styles.previewImage} // Вы можете сохранить этот стиль или изменить его
        />
        <button className={styles.previewButton}>
          <Link to="/stretch2">Free</Link>
          {/* buy */}
        </button> 
    </div>
    <div className={styles.classWrapper}>
        <img
            src={booty1} // Используйте импортированное изображение или URL
            alt="booty1"
            className={styles.previewImage} // Вы можете сохранить этот стиль или изменить его
        />
        <button className={styles.previewButton}>
          <Link to="/booty1">Free</Link>
          {/* buy */}
        </button> 
    </div>
  </div>
}

export default ClassesWrapper