import React, { useState } from 'react';
import styles from './Merch.module.scss'
import { SmileOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { Image } from 'antd';

import Modal from './Modal';
import Cart from './Cart';
import { BsCart4 } from "react-icons/bs";

const Merch = () => {
    const [cart, setCart] = useState([]);
    const [showCart, setShowCart] = useState(false);

    const image1 = 'https://i.ibb.co/BGNMQPh/dizajn1.jpg'
    const image2 = 'https://i.ibb.co/Fs6d3Fc/dizajn2.jpg'
    const image3 = 'https://i.ibb.co/6D2j6tp/dizajn3.jpg'
    const image4 = 'https://i.ibb.co/jgtfkSW/dizajn4.jpg'

    const merch = [
        {
            id: 1,
            image: image1,
            headline: 'T-shirt',
            price: '20',
            sizes: ['S', 'M', 'L', 'XL']
        },
        {
            id: 2,
            image: image2,
            headline: 'T-shirt',
            price: '20',
            sizes: ['S', 'M', 'L', 'XL']
        },
        {
            id: 3,
            image: image3,
            headline: 'T-shirt',
            price: '20',
            sizes: ['S', 'M', 'L', 'XL']
        },
        {
            id: 4,
            image: image4,
            headline: 'T-shirt',
            price: '20',
            sizes: ['S', 'M', 'L', 'XL']
        }
    ];

    const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.open({
      message: 'Product added to cart!',
      icon: (
        <SmileOutlined
          style={{
            color: 'black',
          }}
        />
      ),
    });
};

    // const addToCart = (product, size) => {
    //     if (!size) {
    //         alert('Пожалуйста, выберите размер перед добавлением в корзину.');
    //         return;
    //     }
    //     const productWithSize = { ...product, size };
    //     setCart([...cart, productWithSize]);
    //     openNotification();
    // };
    const addToCart = (product, event) => {
        // Prevent the default form submission if the button is inside a form
        event.preventDefault();
    
        // Use event.currentTarget if the event is attached to the button
        const button = event.currentTarget;
        const productDiv = button.closest(`.${styles.product}`);
        const select = productDiv.querySelector('select[name="size"]');
        const size = select.value;
    
        if (!size) {
            alert('Please choose a size before adding to cart.');
            return;
        }
        const productWithSize = { ...product, size };
        setCart([...cart, productWithSize]);
        openNotification();
    };
    
    
    

    // Удаляем товар из корзины
    const removeFromCart = (productId) => {
        setCart(cart.filter(item => item.id !== productId));
    };

    // Обработчик отправки формы
    const submitOrder = (e) => {
        e.preventDefault();
        // Здесь можно добавить логику для отправки данных на вашу почту
        alert('Order submitted!'); // Просто для демонстрации
    };



    return <div className={styles.merchWrapper}>
        <div className={styles.header}>
            <p className={styles.title}>Sofiia<br/>Diachenko<br/>Dance<br/>Studio</p>
            <p className={styles.subtitle}>they call it dance.<br/>I call it life</p>
        </div>
        <BsCart4 className={styles.cart} onClick={() => setShowCart(!showCart)}/>
            <Modal onClose={() => setShowCart(false)} show={showCart}>
                {showCart && <Cart cartItems={cart} removeFromCart={removeFromCart} submitOrder={submitOrder} />}
            </Modal>
        <div className={styles.products}>
            {merch.map((product) => (
                <div key={product.id} className={styles.product}>
                    <Image src={product.image} alt={product.headline} />
                    <h2>{product.headline}</h2>
                    <p>€{product.price}</p>
                    <select name="size" defaultValue="">
                        <option value="" disabled>Choose size</option>
                        {product.sizes.map((size) => (
                            <option key={size} value={size}>{size}</option>
                        ))}
                    </select>
                    {contextHolder}
                    <button onClick={(e) => addToCart(product, e)}>Add to cart</button>
                </div>
            ))}
        </div>
    </div>
}

export default Merch;