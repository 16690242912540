export const SliderData = [
    {
      image:
        'https://i.ibb.co/jV2PpBV/reviews3.jpg'
    },
    {
      image:
        'https://i.ibb.co/WWQdLwY/reviews2.jpg'
    },
    {
      image:
        'https://i.ibb.co/yy1wY4B/reviews1.jpg'
    },
    {
      image:
        'https://images.unsplash.com/photo-1475189778702-5ec9941484ae?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1351&q=80'
    },
    {
      image:
        'https://images.unsplash.com/photo-1503177119275-0aa32b3a9368?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80'
    }
  ];
  

