import React from 'react'
import Header from '../../components/Header/Header'
import Footer from './../../components/Footer/Footer'
import Yoga1 from './../../components/Classes/Yoga1/Yoga1'

const Yoga1Class = () => {
  return <>
    <Header />
    <Yoga1 />
    <Footer />
  </>
}

export default Yoga1Class