import styles from './Breathe.module.scss';
import React, { useContext, useCallback, useEffect, useState } from 'react';
import AuthContext from '../../AuthContext'
import { useNavigate } from 'react-router-dom';

const Breathe = () => {
  const preview = 'https://i.ibb.co/Rj6sRZt/breathe-Preview-Photo.jpg'
  const { isLoggedIn, user, openModal } = useContext(AuthContext);
  const navigate = useNavigate();
  const priceId = "price_1OwPDvIfyFx81tFmba61m9hQ";
  const [videoUrl, setVideoUrl] = useState('');
  const videoName = 'breathe';

  const hasAccessToLesson = useCallback((user) => {
    console.log(user && user.videoLessons && user.videoLessons.breathe);
    return user && user.videoLessons && user.videoLessons.breathe;
  }, []);

  useEffect(() => {
    if (hasAccessToLesson(user)) {
      // Если пользователь имеет доступ к уроку, делаем запрос к серверу за подписанным URL
      const getVideoUrl = async () => {
        try {
          const response = await fetch(`https://us-central1-sdds-413014.cloudfunctions.net/api/get-video-url?videoName=${encodeURIComponent(videoName)}`);
          if (!response.ok) {
            throw new Error('Server returned an error when requesting the video URL');
          }
          const data = await response.json();
          setVideoUrl(data.videoUrl);
        } catch (error) {
          console.error('Error when obtaining the video URL', error);
        }
      };
      
      getVideoUrl();
    }
  }, [hasAccessToLesson, videoName, user]);


  // useEffect(() => {
  //   if (hasAccessToLesson(user)) {
  //     getSignedUrl(fileName);
  //   }
  // }, [user, hasAccessToLesson]);


  // const getSignedUrl = async (fileName) => {
  //   try {
  //     const response = await fetch(`https://us-central1-sdds-413014.cloudfunctions.net/api/generate-signed-url?fileName=${encodeURIComponent(fileName)}`);
  //     if (!response.ok) {
  //       const errorText = await response.text();
  //       console.error('Error response:', errorText);
  //       throw new Error('Server returned an error when requesting the signed URL');
  //     }
  //     const data = await response.json();
  //     setVideoUrl(data.url);
  //     console.log(data.url);
  //   } catch (error) {
  //     console.error('Error when obtaining the signed URL', error);
  //   }
  // };

  const handleBuyClick = async (priceId) => {
    if (!isLoggedIn) {
      openModal();
    } else {
      const response = await fetch('https://us-central1-sdds-413014.cloudfunctions.net/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId }),
      });
    
      const { clientSecret } = await response.json();

      navigate(`/checkout?clientSecret=${clientSecret}&priceId=${priceId}`);
    }
  };


  return (
    <div className={styles.breatheClassWrapper}>
      <video controls className={styles.videoBreathe} poster={preview}>
        {hasAccessToLesson(user) && <source src={videoUrl} type="video/mp4" />}
        Your browser does not support the video tag.
      </video>
      <div className={styles.content}>
        <h1 className={styles.headline}>Headline</h1>
        <p className={styles.text}>some text some text some text some text some text some text</p>
        {!hasAccessToLesson(user) && (
          <div>
            <button className={styles.buyButton} onClick={() => handleBuyClick(priceId)}>Buy for 25 euro</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Breathe;
