import Breathe from "../../components/Classes/Breathe/Breathe"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"

const BreatheClass = () => {
  return <>
    <Header />
    <Breathe />
    <Footer />
  </>
}

export default BreatheClass