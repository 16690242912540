import React, { useState } from 'react';
import styles from './Letter.module.scss';
import Modal from './Modal'; // Предполагим, что у вас есть компонент Modal

const Trainers = () => {
  const sofushka = 'https://i.ibb.co/hcxT7NT/sofushka.jpg';
  const [isLetterOpen, setIsLetterOpen] = useState(false);
  const [language, setLanguage] = useState('EN'); // Добавляем состояние для текущего выбранного языка

  const toggleLetter = () => {
    setIsLetterOpen(!isLetterOpen);
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <div className={styles.trainersWrapper}>
      <div className={styles.sofushkaImg}>
        <img src={sofushka} alt="Sofushka" />
      </div>
      <div className={styles.sofushkaText}>
        <p>I wrote a letter to you. It's very important for me so please read it carefully</p>
        <button onClick={toggleLetter} className={styles.sofushkaButton}>Read my letter</button>
      </div>
      <div className={styles.sofushkaVersus}>
        <p className={styles.not}>Tiring training sessions that demand perfection and competition. Strict trainers and complex exercises that aren't beginner-friendly. The worry of making mistakes and being judged. Bland and uncomfortable spaces.</p>
        <p className={styles.yes}>Classes that are full of fun and you'll look forward to. Any beginner can join in without stress. A friendly and easy-going environment. Caring instructors who help everyone. A place that feels like coming home.</p>
      </div>
      {isLetterOpen && (
        <Modal onClose={toggleLetter}>
          <div className={styles.languageSelection}>
            <button
              onClick={() => handleLanguageChange('EN')}
              className={`button ${language === 'EN' ? 'active' : ''}`}
            >
              English
            </button>
            <button
              onClick={() => handleLanguageChange('UA')}
              className={`button ${language === 'UA' ? 'active' : ''}`}
            >
              Українська
            </button>
            <button
              onClick={() => handleLanguageChange('SK')}
              className={`button ${language === 'SK' ? 'active' : ''}`}
            >
              Slovenčina
            </button>
          </div>
          {/* Пример статического разделения на абзацы */}
          <div className={styles.letterText}>
            {language === 'UA' && (
              <>
                <p>Привіт, моя дорога учениця. Якщо ти читаєш цей лист, значить, твоє життя скоро набуде нових барв і зміниться на краще, оскільки ти приєднаєшся до нашої SDDS родини!</p>
                <p>Для тебе я не просто викладач танців, я твій друг, наставник, людина, яка підтримає тебе і буде поруч на всьому твоєму танцювальному шляху!</p>
                <p>Усе своє життя я хотіла присвятити чомусь значущому. Тому, чим я буду горіти все своє життя. Якось я замислилася: що ж змушує мене відчути себе дійсно живою? Коли я відчуваю себе щасливою? Коли я змогла по-справжньому зрозуміти, хто я?</p>
                <p>Відповідь була проста: танець. Я танцювала все своє життя. Коли мені було сумно, коли мені було весело, у найважчі моменти мого життя моєю підтримкою завжди був танець. Він живив мене енергією і направляв мене по життю. Найважливіші усвідомлення приходили до мене саме у моменти імпровізації, розтяжки або під час виступу на сцені.</p>
                <p>Я не хочу, щоб думка про танець викликала у тебе тривогу чи страх, я не хочу, щоб імпровізація здавалася страшним сном. Моєю метою є зробити твоє життя кращим. Наповнити його спогадами і змусити тебе відчути справжню свободу! Свободу бути собою!</p>
                <p>Танець змінив мене, вилікував, наповнив і надихнув. Дозволь мені поділитися цим з тобою! ❤️</p>
                <p>У нашій студії ти точно знайдеш щось для себе.</p>
              </>
            )}
            {language === 'EN' && (
              <>
                <p>Hello, my dear student. If you're reading this letter, it means your life is about to gain new colors and change for the better, as you will join our SDDS family!</p>
                <p>To you, I'm not just a dance teacher; I'm your friend, mentor, someone who will support you and be there throughout your dance journey!</p>
                <p>I've always wanted to dedicate my life to something meaningful. Something I would be passionate about all my life. At some point, I wondered: what makes me feel truly alive? When did I feel happy? When could I truly understand who I am?</p>
                <p>The answer was simple: dance. I have danced all my life. When I was sad, when I was happy, in the hardest moments of my life, dance was always my support. It fed me energy and guided me through life. The most important realizations came to me in moments of improvisation, stretching, or on stage.</p>
                <p>I don't want the thought of dance to cause you anxiety or fear. I don't want improvisation to seem like a terrifying dream. My goal is to make your life better. To fill it with memories and make you feel the true freedom! The freedom to be yourself!</p>
                <p>Dance changed me, healed me, filled me, and inspired me. Let me share this with you! ❤️</p>
                <p>In our studio, you'll definitely find something for yourself.</p>
              </>
            )}
            {language === 'SK' && (
              <>
                <p>Zdravím ťa, môja drahá študentka❤️ Ak čítaš tento list, znamená to, že tvoj život čoskoro získa nové farby a zmení sa k lepšiemu, pretože sa pripojíš k našej rodine SDDS!</p>
                <p>Pre teba nie som len tanečna trénerka, som tvoja kamoška, mentor, človek, ktorý ťa podporí a bude po tvojom boku počas celéj tvojej tanečnéj cesty!</p>
                <p>Celý môj život som chcela venovať niečomu významnému. Niečomu, čím budem žiť celý svoj život. Raz som sa zamyslela: čo ma naozaj robí živou? Kedy som sa cítila šťastná? Kedy som naozaj pochopila, kto som?</p>
                <p>Odpoveď bola jednoduchá: tanec. Tancovala som počas celého svojho života. Keď som bola smutná, keď som bola veselá, v najťažších chvíľach môjho života, vždy mi oporou bol tanec. Napĺňal ma energiou a usmerňoval ma v živote. Najdôležitejšie uvedomenia mi prišli práve v momentoch improvizácie, strečingu alebo na scéne.</p>
                <p>Neželám si, aby myšlienka na tanec vo tebe vyvolávala úzkosť alebo strach, nechcem, aby ti improvizácia pripadala ako zlý sen. Mojím cieľom je urobiť tvoj život lepším. Naplniť ho spomienkami a nechať ťa pocítiť skutočnú slobodu! Slobodu byť sámou sebou!</p>
                <p>Tanec ma zmenil, uzdravil, naplnil a inšpiroval. Dovoľ mi podeliť sa o tom s tebou! ❤️</p>
                <p>V našom štúdiu určite nájdeš niečo pre seba.</p>
              </>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Trainers;
