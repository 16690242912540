import React from 'react';
import styles from './StylesAndTrainers.module.scss';

const StylesAndTrainers = () => {
  const highHeelsImg = 'https://i.ibb.co/x7xhPM6/IMG-8831.jpg';
  const ladyChoreoImg = 'https://i.ibb.co/TvYBMHn/IMG-8272.jpg';
  const contemporaryImg = 'https://i.ibb.co/mXtCzG1/IMG-7997.jpg';
  const stretchingImg = 'https://i.ibb.co/RHmgJtr/IMG-8462.jpg';
  const twerkImg = 'https://i.ibb.co/KXbH535/IMG-8353.jpg';


  return (
    <div className={styles.stylesAndTrainersWrapper}>
      <div className={`${styles.row}`}>
        <div className={styles.imageContainer}>
          <img src={highHeelsImg} alt="High Heels" />
        </div>
        <div className={styles.content}>
          <h2>High Heels - trainer Sofiia</h2>
          <p>Sofushka is the best dancer forever in the whole world. If you want to learn dance you need come to her</p>
        </div>
      </div>

      <div className={`${styles.row} ${styles.reverse}`}>
        <div className={styles.imageContainer}>
          <img src={ladyChoreoImg} alt="Lady Choreo" />
        </div>
        <div className={styles.content}>
          <h2>Lady Choreo - trainer Mari</h2>
          <p>Mari is a trainer</p>
        </div>
      </div>

      <div className={`${styles.row}`}>
        <div className={styles.imageContainer}>
          <img src={contemporaryImg} alt="Contemporary" />
        </div>
        <div className={styles.content}>
          <h2>Contemporary - trainer Viktoria</h2>
          <p>Viktoriia is a trainer</p>
        </div>
      </div>

      <div className={`${styles.row} ${styles.reverse}`}>
        <div className={styles.imageContainer}>
          <img src={stretchingImg} alt="Stretching" />
        </div>
        <div className={styles.content}>
          <h2>Stretching - trainer Aleksandra</h2>
          <p>Aleksandra is a trainer</p>
        </div>
      </div>

      <div className={`${styles.row}`}>
        <div className={styles.imageContainer}>
          <img src={twerkImg} alt="Twerk" />
        </div>
        <div className={styles.content}>
          <h2>Twerk - trainer Kate</h2>
          <p>Kate is a trainer</p>
        </div>
      </div>
    </div>
  );
};

export default StylesAndTrainers;









// import styles from './StylesAndTrainers.module.scss'
// import highHeelsImg from '../../fig/highHeels.jpg'
// import ladyChoreoImg from '../../fig/ladyChoreo.jpg'

// const StylesAndTrainers = () => {
//   return <div className={styles.stylesAndTrainersWrapper}>
//     <div className={styles.highHeelsWrapper}>
//         <div className={styles.highHeelsImgWrapper}>
//             <img className={styles.highHeelsImg} src={highHeelsImg} alt="" />
//         </div>
//         <div className={styles.highHeelsText}>
//             <h2>High Heels - trainer Sofiia</h2>
//             <p>Sofushka is the best dancer forever in the whole world. If you want to learn dance you need come to her</p>
//         </div>
//     </div>

//     <div className={styles.ladyChoreoWrapper}>
//         <div className={styles.ladyChoreoImgWrapper}>
//             <img className={styles.ladyChoreoImg} src={ladyChoreoImg} alt="" />
//         </div>
//         <div className={styles.ladyChoreoText}>
//             <h2>Lady Choreo - trainer Mari</h2>
//             <p>Mari also isn't a bad trainer. you can come to her too</p>
//         </div>
//     </div>
//   </div>
// }

// export default StylesAndTrainers