// Cart.js
import { MdDeleteForever } from "react-icons/md";
import React from 'react';
import './Cart.css'; // Убедитесь, что у вас есть этот файл CSS и он подключен


const emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$";

const Cart = ({ cartItems, removeFromCart, submitOrder }) => {
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const form = e.target;
    //     const email = form.email.value; // получаем значение из поля с e-mail

    //     // Регулярное выражение для проверки e-mail
    //     if (!email.match(emailPattern)) {
    //         alert("Please enter a valid email address.");
    //         return;
    //     }

    //     // Здесь можно добавить дальнейшую логику обработки формы
    //     submitOrder(); // Можно передать нужные данные
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const email = form.email.value; // получаем значение из поля с e-mail
    
        // Регулярное выражение для проверки e-mail
        if (!email.match(emailPattern)) {
            alert("Please enter a valid email address.");
            return;
        }
    
        // Optionally, you could compile all cart items into a single string
        // and set that as the value for a hidden field.
        const cartDetails = cartItems.map(item => `Item: ${item.headline}, Size: ${item.size}, Price: ${item.price}`).join('; ');
        const cartDetailsField = document.createElement('input');
        cartDetailsField.type = 'hidden';
        cartDetailsField.name = 'cartDetails';
        cartDetailsField.value = cartDetails;
    
        form.appendChild(cartDetailsField);
        
        form.submit(); // This sends the form to the URL in the 'action' attribute
    };
    

    return (
        <div className="cart-container">
            <h2>Your Cart</h2>
            {cartItems.length === 0 ? (
                <p>Your cart is empty</p>
            ) : (
                <div>
                    {cartItems.map((item, index) => (
                        <div className="cart-item" key={`${item.id}-${item.size}`}>
                            <img src={item.image} alt={item.headline} className="cart-item-image"/>
                            <div className="cart-item-info">
                                <h4>{item.headline}</h4>
                                <p>Size: {item.size}</p>
                                <p>Price: ${item.price}</p>
                            </div>
                            <MdDeleteForever onClick={() => removeFromCart(item.id)} />
                            {index !== cartItems.length - 1 && <hr />} {/* Разделяющая линия между элементами */}
                        </div>
                    ))}

                    <form action="https://formspree.io/f/xgegndqj"
                          method="POST"
                          onSubmit={handleSubmit} 
                          className="order-form"
                    >
                        <h3>Order Details</h3>
                        <input type="text" placeholder="Your Name" name="name" required />
                        <input type="email" placeholder="Your Email" name="email" required pattern={emailPattern} title="Please enter a valid email address." />
                        <textarea placeholder="Address" name="address" required></textarea>
                        <textarea placeholder="Additional Notes" name="additionalNotes"></textarea>
                        <p>We will send you the payment details via email, and the total price may vary depending on shipping costs.</p>
                        {cartItems.map(item => (
                        <input type="hidden" name={`product-${item.id}`} value={`Size: ${item.size}, Price: ${item.price}`} key={item.id} />))}
                        <button className="submit" type="submit">Submit Order</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Cart;

