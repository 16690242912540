import styles from './HomeOurProjects.module.scss'
// import React, {useState} from 'react'
import { Carousel } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

// import redVideo from '../../fig/red.mp4';
// import americanVideo from '../../fig/american.mp4';
// import pokerFaceVideo from '../../fig/pokerFace.mp4';
// import estelVideo from '../../fig/estel.mp4';


const HomeOurProjects = () => {
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    const dotPosition = 'top';
  
  
    return (
    <>
    <Carousel dotPosition={dotPosition} className={styles.ourProjects} afterChange={onChange}>
        <div className={styles.project}>
            <video className={styles.contentStyle} autoPlay loop muted playsInline>
                <source src="https://storage.googleapis.com/sdds_videos/red.mp4" type="video/mp4" />
            </video>
            <button className={styles.projectButton}>
                <Link to="/our-projects">Our projects</Link> 
            </button>
            <p>Choreo "Speechless"</p>
        </div>
        <div className={styles.project}>
            <video className={styles.contentStyle} autoPlay loop muted playsInline>
                <source src="https://storage.googleapis.com/sdds_videos/pokerFace.mp4" type="video/mp4" />
            </video>
            <button className={styles.projectButton}>
                <Link to="/our-projects">Our projects</Link>
            </button>
            <p>Videoklip "Poker Face"</p>
        </div>
        <div className={styles.project}>
            <video className={styles.contentStyle} autoPlay loop muted playsInline>
                <source src="https://storage.googleapis.com/sdds_videos/american.mp4" type="video/mp4" />
            </video>
            <button className={styles.projectButton}>
                <Link to="/our-projects">Our projects</Link>
            </button>
            <p> Work "American Funeral"</p>
        </div>
        <div className={styles.project}>
            <video className={styles.contentStyle} autoPlay loop muted playsInline>
                <source src="https://storage.googleapis.com/sdds_videos/estel.mp4" type="video/mp4" />
            </video>
            <button className={styles.projectButton}>
                <Link to="/our-projects">Our projects</Link>
            </button>
            <p>Commercial work for the clinic "Estel"</p>
        </div>
    </Carousel> 
    </>
    )
}

export default HomeOurProjects