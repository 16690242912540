import Stretch1 from "../../components/Classes/Stretch1/Stretch1"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"

const StretchClass0 = () => {
  return <>
    <Header />
    <Stretch1 />
    <Footer />
  </>
}

export default StretchClass0