import styles from './HomeBookClasses.module.scss'
// import class1Video from '../../fig/breathePreview.MP4'
// import class2Video from '../../fig/stretchPreview.mp4'
import { Link } from 'react-router-dom';
import React from 'react';

const HomeBookClasses = () => {
  const handleMouseEnter = (e) => {
    e.target.play();
  }

  const handleMouseLeave = (e) => {
    e.target.pause();
  }

  return  <div className={styles.homeBookClassesWrapper}>
    <video className={styles.bookClassVideo} loop muted playsInline onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <source src="https://storage.googleapis.com/sdds_videos/breathePreview.MP4" type="video/MP4" />
    </video>
    <video className={styles.bookClassVideo} loop muted playsInline onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <source src="https://storage.googleapis.com/sdds_videos/stretchPreview.mp4" type="video/mp4" />
    </video>

    <button className={styles.bookButton}>
      <Link to="/book-classes">Book classes</Link> 
    </button>
  </div>
}

export default HomeBookClasses