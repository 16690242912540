import { Link } from 'react-router-dom';
import React, { useState, useContext } from 'react';
import styles from './Header.module.scss'
// import logoVideo from '../../fig/logo.mp4';
import Modal from './Modal'; // Импортируйте ваш компонент модального окна
import LoginForm from './LoginForm'; // Импортируйте форму входа
import RegisterForm from './RegisterForm';
// import axios from 'axios';
import AuthContext from '../AuthContext'
// import logo from '../../fig/logo5.png'

const Header = () => {
  const logo = 'https://i.ibb.co/BCdhGVv/logo5.png'
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { login, isLoggedIn, openModal, closeModal, isModalOpen } = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const toggleModal = () => {
  //   setIsModalOpen(!isModalOpen);
  // };

  const switchForm = () => {
    setIsLogin(!isLogin);
  };

  const onLoginSuccess = (data) => {
    console.log('Login successful');
    login(data.token);
    closeModal();
  }
  
  const onRegisterSuccess = (data) => {
    console.log('Registration successful:', data);
    closeModal();
    login(data.token);
  };

  return <div className={styles.headerWrapper}>
    <header className={styles.header}>
      
      <img
        src={logo} // Используйте импортированное изображение или URL
        alt="Logo"
        className={styles.headerLogoImage} // Вы можете сохранить этот стиль или изменить его
      />
      

      
      {/* Ссылки на след страницы */}
      <nav className={`${styles.links} ${isMenuOpen ? styles.menuOpen : ''}`}>
        <Link to="/" onClick={toggleMenu}>Home</Link> 
        <Link to="/what-is-sdds" onClick={toggleMenu}>What is SDDS?</Link>
        <Link to="/our-projects" onClick={toggleMenu}>Our projects</Link>
        <Link to="/buy-merch" onClick={toggleMenu}>Buy merch</Link>
        <Link to="/book-classes" onClick={toggleMenu}>Book classes</Link>  
        <Link to="/reserve-lesson" onClick={toggleMenu}>Reserve lesson</Link> 
      </nav>

      {/* Кнопка для входа в свой аккуант */}
      <div className={styles.buttons}>
        <button className={styles.menuButton} onClick={toggleMenu}>Menu</button>
        {!isLoggedIn && <button onClick={openModal} className={styles.loginButton}>Log in</button>}
        {isModalOpen && (
        <Modal onClose={closeModal}>
          {isLogin ? (
            <>
              <LoginForm onLoginSuccess={onLoginSuccess} />
              <p>
                You don't have an account? <button onClick={switchForm}>Register</button>
              </p>
            </>
          ) : (
            <>
              <RegisterForm onRegisterSuccess={onRegisterSuccess} />
              <p>
                You have an account? <button onClick={switchForm}>Log In</button>
              </p>
            </>
          )}
        </Modal>
       )} 
      </div>

    </header>
  </div>
}

export default Header

