import Header from "../../components/Header/Header"
import Merch from "../../components/Merch/Merch"
import Footer from "../../components/Footer/Footer"

const BuyMerch = () => {
  return (
    <>
        <Header />
        <Merch />
        <Footer />
    </>
  )
}

export default BuyMerch