import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HomeBookClasses from "../../components/HomeBookClasses/HomeBookClasses";
import HomeOurProjects from "../../components/HomeOurProjects/HomeOurProjects";
import HomeReviews from "../../components/HomeReviews/HomeReviews";
import HomeWhatIs from "../../components/HomeWhatIs/HomeWhatIs";
import OurStudents from "../../components/OurStudents/OurStudents";
import styles from './Home.module.scss'

const Home = () => {
  const image = "https://i.ibb.co/N2m5nBv/13-1.jpg";
  return (
    <>
      <Header />
      <HomeWhatIs />
      <img className={styles.img} src={image} alt="sofuk" />
      <HomeOurProjects />
      <HomeBookClasses />
      <HomeReviews />
      <OurStudents />
      <Footer />
    </>
  )
}

export default Home