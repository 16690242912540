import styles from './HomeWhatIs.module.scss'
// import whatIsSDDSVideo from '../../fig/whatIsSDDS.mp4';
// import whatIsSDDSVideo from 'https://sagemaker-eu-north-1-809487632113.s3.eu-north-1.amazonaws.com/american.mp4'
import { Link } from 'react-router-dom';
import React from 'react';

const HomeWhatIs = () => {
    return <div className={styles.whatIsWrapper}>
    <div className={styles.whatIsSDDS}>
        <video className={styles.whatIsSDDSVideo} autoPlay loop muted playsInline>
            <source src="https://storage.googleapis.com/sdds_videos/whatIsSDDS.mov" type="video/mp4" />
        </video>
        <button className={styles.whatIsbutton}>
            <Link to="/what-is-sdds">What is SDDS?</Link>
        </button>

        <p>
            At our dance studio, we are passionate about the art of dance and committed to fostering creativity, discipline, and self-expression through dance education.
        </p>
    </div> 
  </div>
}

export default HomeWhatIs