import styles from './Booty1.module.scss';

const Booty1 = () => {
  return (
    <div className={styles.bootyClassWrapper}>
      <iframe className={styles.iframe} src="https://www.youtube.com/embed/-1_lULQhCZM?si=yBomBF6rBMSa0_O_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      <div className={styles.content}>
        <h1 className={styles.headline}>Headline</h1>
        <p className={styles.text}>some text some text some text some text some text some text</p>
      </div>
    </div>
  );
}

export default Booty1;