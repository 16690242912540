// import styles from './BookClasses.module.scss'
import ClassesWrapper from '../../components/ClassesWrapper/ClassesWrapper'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// import React, { useContext } from 'react';
// import AuthContext from '../../components/AuthContext';

const BookClasses = () => {
  // const { isLoggedIn } = useContext(AuthContext);
  // const token = localStorage.getItem('token');


  return <>
    <Header />  
    <ClassesWrapper />
    <Footer />
  </>
}

export default BookClasses