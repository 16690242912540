import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Booty1 from '../../components/Classes/Booty1/Booty1'

const Booty1Class = () => {
  return <>
    <Header />
    <Booty1 />
    <Footer />
  </>
}

export default Booty1Class