import styles from './TimeTable.module.scss'

const TimeTable = () => {
    return <div className={styles.timeTableWrapper}>
        <table>
                <thead>
                    <tr>
                        <th>Weekday</th>
                        <th>Time</th>
                        <th>Style</th>
                        <th>Level</th>
                        <th>Teacher</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Monday</td>
                        <td>20:00 - 21:00</td>
                        <td>Lady Choreo</td>
                        <td>all levels</td>
                        <td>Mari</td>
                    </tr>

                    <tr>
                        <td>Tuesday</td>
                        <td>20:00 - 21:00</td>
                        <td>High Heels</td>
                        <td>all levels</td>
                        <td>Sofiia</td>
                    </tr>

                    <tr>
                        <td>Wednesday</td>
                        <td>14:30 - 16:00</td>
                        <td>Contemporary</td>
                        <td>all levels</td>
                        <td>Sofiia</td>
                    </tr>

                    <tr>
                        <td>Thursday</td>
                        <td>19:30 - 20:30</td>
                        <td>Lady Choreo</td>
                        <td>all levels</td>
                        <td>Mari</td>
                    </tr>

                    <tr>
                        <td>Friday</td>
                        <td>19:30 - 20:30</td>
                        <td>High Heels</td>
                        <td>all levels</td>
                        <td>Sofiia</td>
                    </tr>

                    <tr>
                        <td>Saturday</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Sunday</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>


                </tbody>
            </table>
    </div>
}

export default TimeTable