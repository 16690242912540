import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const fetchUserData = async (token) => {
    try {
      const response = await fetch('https://us-central1-sdds-413014.cloudfunctions.net/api/user', { // Используйте ваш URL сервера
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const userData = await response.json();
      setUser(userData);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsLoggedIn(false);
      sessionStorage.removeItem('token'); // Очистка токена, если он невалиден или возникла ошибка
    }
  };

  useEffect(() => {
    // Проверка наличия токена в localStorage при инициализации
    const token = sessionStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      fetchUserData(token);
    } else {
      setIsLoggedIn(false);
    }
    // console.log('blbalw')
  }, []);

  const checkToken = () => {
    const token = sessionStorage.getItem('token');
    return token != null;
  };

  const login = (token) => {
    sessionStorage.setItem('token', token); 
    setIsLoggedIn(true);
    fetchUserData(token);
  };

  const logout = () => {
    sessionStorage.removeItem('token'); 
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout, checkToken, useEffect, openModal, closeModal, isModalOpen }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

