import styles from './Stretch1.module.scss';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import AuthContext from '../../AuthContext'
import { useNavigate } from 'react-router-dom';


const Stretch0 = () => {
  const preview = 'https://i.ibb.co/KmzY1T0/C19-F9-B4-A-AE40-49-D0-A372-D20-F30598946.jpg'
  const { isLoggedIn, user, openModal } = useContext(AuthContext);
  const navigate = useNavigate();
  const priceId = "price_1OwO2uIfyFx81tFm6cFGiF1I";
  const [videoUrl, setVideoUrl] = useState('');
  const videoName = 'stretch0';

  const hasAccessToLesson = useCallback((user) => {
    return user && user.videoLessons && user.videoLessons.stretch0;
  }, []);

  useEffect(() => {
    if (hasAccessToLesson(user)) {
      // Если пользователь имеет доступ к уроку, делаем запрос к серверу за подписанным URL
      const getVideoUrl = async () => {
        try {
          const response = await fetch(`https://us-central1-sdds-413014.cloudfunctions.net/api/get-video-url?videoName=${encodeURIComponent(videoName)}`);
          if (!response.ok) {
            throw new Error('Server returned an error when requesting the video URL');
          }
          const data = await response.json();
          setVideoUrl(data.videoUrl);
        } catch (error) {
          console.error('Error when obtaining the video URL', error);
        }
      };
      
      getVideoUrl();
    }
  }, [hasAccessToLesson, videoName, user]);

  
  const handleBuyClick = async (priceId) => {
    if (!isLoggedIn) {
      openModal();
    } else {
      const response = await fetch('https://us-central1-sdds-413014.cloudfunctions.net/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId }),
      });
    
      const { clientSecret } = await response.json();

      navigate(`/checkout?clientSecret=${clientSecret}&priceId=${priceId}`);
    }
  };

  return (
    <div className={styles.stretchClassWrapper}>
      <video controls className={styles.videoStretch} poster={preview}>
        {hasAccessToLesson() && <source src={videoUrl} type="video/mp4" />}
        Your browser does not support the video tag.
      </video>
      <div className={styles.content}>
        <h1 className={styles.headline}>Headline</h1>
        <p className={styles.text}>some text some text some text some text some text some text</p>
        {!hasAccessToLesson() && (
          <div>
            <button className={styles.buyButton} onClick={() => handleBuyClick(priceId)}>Buy for $20</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Stretch0;