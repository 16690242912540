import React from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AmericanProject from '../../components/Projects/AmericanProject/AmericanProject'
import EstelProject from '../../components/Projects/EstelProject/EstelProject'
import PokerFaceProject from '../../components/Projects/PokerFaceProject/PokerFaceProject'
import RedProject from '../../components/Projects/RedProject/RedProject'

const OurProjects = () => {
  return (
    <>
      <Header />
      <RedProject />
      <EstelProject />
      <AmericanProject />
      <PokerFaceProject />
      <Footer />
    </>
  )
}

export default OurProjects