import styles from './Footer.module.scss'
import React from 'react'
import { FaInstagram } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { CiFacebook } from "react-icons/ci";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";

const Footer = () => {
    return <footer className={styles.footerWrapper}>
    <div className={styles.footerContent}>
      <span className={styles.companyInfo}>© 2023 SDDS (Sofiia Diachenko Dance Studio)</span>

      <div className={styles.socialLinks}>
      
        <p>Contact us:</p> 
      
        <a href="mailto:sdds.heels@gmail.com" className={styles.socialLink} aria-label="Gmail">
          <BiLogoGmail />
        </a>
        <a href="https://instagram.com/sdds.kosice" className={styles.socialLink} aria-label="Instagram">
          <FaInstagram />
        </a>
        <a href="https://facebook.com/kosice_dance_studio" className={styles.socialLink} aria-label="Facebook">
          <CiFacebook />
        </a>
        <a href="https://tiktok.com/@mydancediary" className={styles.socialLink} aria-label="TikTok">
          <FaTiktok />
        </a>
      
        <p>or watch</p>
        
        <a href="https://youtube.com/@sonyadyachenko8004" className={styles.socialLink} aria-label="Youtube">
          <FaYoutube />
        </a>
      </div>
    </div>
  </footer>
}

export default Footer