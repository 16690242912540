import React, { useState, useEffect, useRef } from 'react';
import styles from './OurStudents.module.scss';

const OurStudents = () => {
    const students1 = 'https://i.ibb.co/wM5CvLH/students1.jpg'
    const students2 = 'https://i.ibb.co/cLpCtf5/students2.jpg'
    const students3 = 'https://i.ibb.co/wBc18qm/students3.jpg'
    const students4 = 'https://i.ibb.co/k4PmXNx/students4.jpg'
    const students5 = 'https://i.ibb.co/rQ6pm1N/students5.jpg'
    const students6 = 'https://i.ibb.co/RQSVTwg/students6.jpg'
    const students7 = 'https://i.ibb.co/pjPGT6G/students7.jpg'
    const students8 = 'https://i.ibb.co/F4cVtFp/students8.jpg'
    const students9 = 'https://i.ibb.co/kMQNVdR/students9.jpg'
    const students10 = 'https://i.ibb.co/ckggc7r/students10.jpg'
    const students11 = 'https://i.ibb.co/ZXWCb6w/students11.jpg'
    const students12 = 'https://i.ibb.co/ZHxYPVY/students12.jpg'
    const students13 = 'https://i.ibb.co/5n4M3Xf/students13.jpg'
    const students14 = 'https://i.ibb.co/yFYWdwF/students14.jpg'
    // const students15 = '../../fig/students15.jpg'
    const students16 = 'https://i.ibb.co/HKXgxh7/students16.jpg'
    const students17 = 'https://i.ibb.co/f94Ypgg/students17.jpg'
    const students18 = 'https://i.ibb.co/7yDfbYf/students18.jpg'
    const students19 = 'https://i.ibb.co/g64Gk6B/students19.jpg'
    const students20 = 'https://i.ibb.co/hWZF7WY/students20.jpg'
    const students21 = 'https://i.ibb.co/F0m0PNg/students21.jpg'
    const students22 = 'https://i.ibb.co/LJ4wpnM/students22.jpg'
    const students23 = 'https://i.ibb.co/FqkN9CC/students23.jpg'
    const students24 = 'https://i.ibb.co/3WdKvMX/students24.jpg'
    const students25 = 'https://i.ibb.co/Lhsx8dN/students25.jpg'
    const students26 = 'https://i.ibb.co/F5tqT75/students26.jpg'
    const students27 = 'https://i.ibb.co/SXjMhsp/students27.jpg'
    const students28 = 'https://i.ibb.co/WVMP14f/students28.jpg'
    const students29 = 'https://i.ibb.co/sVPDDQm/students29.jpg'    
    const students30 = 'https://i.ibb.co/hydpY30/students30.jpg'
    const students31 = 'https://i.ibb.co/FqNZMtd/students31.jpg'
    const video1 = 'https://storage.googleapis.com/sdds_videos/IMG_0941.MOV'
    const video2 = 'https://storage.googleapis.com/sdds_videos/IMG_0945.MOV'
    const video3 = 'https://storage.googleapis.com/sdds_videos/IMG_0943.MOV'
    const video4 = 'https://storage.googleapis.com/sdds_videos/IMG_0939.MOV'
    const video5 = 'https://storage.googleapis.com/sdds_videos/IMG_0389.MOV'

    // Массив импортированных изображений
    const images = [students1, students2, students3, students4, students5, students6, students7, students8, students9, students10, students11, students12, students13, students14, students16, students17, students18, students19, students20, students21, students22, students23, students24, students25, students26, students27, students28, students29, students30, students31, video1, video2, video3, video4, video5];
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const thumbnailsContainerRef = useRef(null);

    const handleThumbnailClick = (image) => {
        setSelectedImage(image);
    };

    const updateSelectedImage = (direction) => {
        const currentIndex = images.indexOf(selectedImage);
        let newIndex = direction === 'left'
            ? currentIndex - 1 < 0 ? images.length - 1 : currentIndex - 1
            : currentIndex + 1 === images.length ? 0 : currentIndex + 1;

        setSelectedImage(images[newIndex]);
    };

    const scrollThumbnails = (direction) => {
        updateSelectedImage(direction);
    };

    useEffect(() => {
        const thumbnailsContainer = thumbnailsContainerRef.current;
        const activeThumbnail = thumbnailsContainer.querySelector(`.${styles.activeThumbnail}`);

        if (activeThumbnail) {
            const thumbnailOffset = activeThumbnail.offsetLeft + activeThumbnail.offsetWidth / 2;
            const scrollPosition = thumbnailOffset - thumbnailsContainer.offsetWidth / 2;
            thumbnailsContainer.scrollLeft = scrollPosition;
        }
    }, [selectedImage]);

    return (
        <div className={styles.ourStudentsWrapper}>
            <div className={styles.selectedImage}>
                {selectedImage.endsWith('.jpg') ? (
                    <img key={selectedImage} className={styles.selectedImg} src={selectedImage} alt="Selected" />
                ) : (
                    <video key={selectedImage} controls autoPlay className={styles.selectedVideo}>
                        <source src={selectedImage} type="video/mp4" />
                        Your browser does not support video.
                    </video>
                )}
            </div>
            <button className={`${styles.thumbnailsButton} ${styles.left}`} onClick={() => scrollThumbnails('left')}>&lt;</button>
            <div className={styles.thumbnailsWrapper}>
                <div className={styles.thumbnails} ref={thumbnailsContainerRef}>
                    {images.map((image, index) => {
                        const isVideo = !image.endsWith('.jpg');
                        return (
                            <div key={index} className={image === selectedImage ? `${styles.thumbnail} ${styles.activeThumbnail}` : styles.thumbnail} onClick={() => handleThumbnailClick(image)}>
                                {isVideo ? (
                                    // Предполагается, что у вас есть какой-то способ получить миниатюру для видео
                                    <img className={styles.playIcon} src={`https://i.ibb.co/Wvdft90/play-icon.png`} alt={`Thumbnail ${index}`} />
                                ) : (
                                    <img src={image} alt={`Thumbnail ${index}`} />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <button className={`${styles.thumbnailsButton} ${styles.right}`} onClick={() => scrollThumbnails('right')}>&gt;</button>
        </div>
    );
};

export default OurStudents;