import React from 'react';
import styles from './Modal.module.scss'; // Создайте стили для модального окна

const Modal = ({ onClose, children }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          &times; {/* Крестик для закрытия */}
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
