// import React from "react";
// import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';


// const stripePromise = loadStripe("pk_live_51OiFevIfyFx81tFmig9eo9B5RVXxxc12629iumNri7dxpfij7e9QmG0catxIlWxsQUXdjIIcSv0KDYvIW72HSJT900VuohOnwU", {
//   googlePay: true
// }); 

// const CheckoutPage = () => {
//   const searchParams = new URLSearchParams(window.location.search);
//   const clientSecret = searchParams.get('clientSecret');
  

//   return (
//     <div id="checkout">
//       {clientSecret && (
//         <EmbeddedCheckoutProvider stripe={stripePromise} options={{clientSecret}}>
//           <EmbeddedCheckout />
//         </EmbeddedCheckoutProvider>
//       )}
//     </div>
//   );
// }

// export default CheckoutPage;

import React, { useEffect } from "react";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe("pk_live_51OiFevIfyFx81tFmig9eo9B5RVXxxc12629iumNri7dxpfij7e9QmG0catxIlWxsQUXdjIIcSv0KDYvIW72HSJT900VuohOnwU", {
  googlePay: true
});

const CheckoutPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const clientSecret = searchParams.get('clientSecret');

  useEffect(() => {
    if (!clientSecret) {
      console.warn("No clientSecret found in URL parameters.");
    }

    stripePromise.then(stripe => {
      if (!stripe) {
        console.error("Stripe object is not available");
        return;
      }

      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo Total',
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest.canMakePayment().then(result => {
        if (result) {
          console.log("Google Pay is available");
        } else {
          console.log("Google Pay is not available");
        }
      }).catch(error => {
        console.error("Error checking payment availability:", error);
      });
    });
  }, [clientSecret]);

  return (
    <div id="checkout">
      {clientSecret ? (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        <p>Client secret not provided. Please check the URL or query parameters.</p>
      )}
    </div>
  );
}

export default CheckoutPage;
