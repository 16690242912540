import styles from './FullBody2.module.scss';

const FullBody2 = () => {
  return (
    <div className={styles.fullBodyClassWrapper}>
      <iframe className={styles.iframe} src="https://www.youtube.com/embed/D5Mv7wkCiw8?si=S7H50-5bJEWQnLni" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      <div className={styles.content}>
        <h1 className={styles.headline}>Headline</h1>
        <p className={styles.text}>some text some text some text some text some text some text</p>
      </div>
    </div>
  );
}

export default FullBody2;