import React, { useState } from 'react';
import { SliderData } from './SliderData.js';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";


const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [prev, setPrev] = useState(-1);
  const length = slides.length;

  const nextSlide = () => {
    setPrev(current);
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setPrev(current);
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

    return <div className='sliderWrapper'>
        <div className='headline'>
            <p>Love from our students:</p>
        </div>
        <section className='slider'>
        <FaChevronLeft className='left-arrow' onClick={prevSlide} />
        <FaChevronRight className='right-arrow' onClick={nextSlide} />
        {SliderData.map((slide, index) => {
          let className = 'slide';
          if (index === current) {
            className += ' active';
          } else if (index === prev) {
            className += ' prev';
          }

          return (
            <div className={className} key={index}>
              <img src={slide.image} className='image' alt=""/>
            </div>
          );
        })}
        </section>
    </div>
};

export default ImageSlider;

