import React, { useState, useEffect, useRef } from 'react';
import styles from './EstelProject.module.scss';
// import video1 from '../../../fig/estel.mp4'
// import video2 from '../../../fig/red2.mp4'

const EstelProject = () => {
    const image1 = 'https://i.ibb.co/WVMP14f/students28.jpg'
    const image2 = 'https://i.ibb.co/cLpCtf5/students2.jpg'
    const image3 = 'https://i.ibb.co/wBc18qm/students3.jpg'
    const image4 = 'https://i.ibb.co/k4PmXNx/students4.jpg'
    // Массив импортированных изображений
  
    const estelVideo = "https://storage.googleapis.com/sdds_videos/estel.mp4"
  
    const images = [estelVideo, image1, image2, image3, image4];
  // Состояние для выбранного изображения
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const thumbnailsContainerRef = useRef(null);

    const handleThumbnailClick = (image) => {
        setSelectedImage(image);
    };


    const updateSelectedImage = (direction) => {
        const currentIndex = images.indexOf(selectedImage);
        let newIndex;

        if (direction === 'left') {
            newIndex = currentIndex - 1 < 0 ? images.length - 1 : currentIndex - 1;
        } else {
            newIndex = currentIndex + 1 === images.length ? 0 : currentIndex + 1;
        }

        setSelectedImage(images[newIndex]);
    };

    const scrollThumbnails = (direction) => {
        updateSelectedImage(direction);
    };

    // Эффект для прокрутки миниатюрного изображения в видимую область
    useEffect(() => {
        const thumbnailsContainer = thumbnailsContainerRef.current;
        const activeThumbnail = thumbnailsContainer.querySelector(`.${styles.activeThumbnail}`);

        if (activeThumbnail) {
            const thumbnailOffset = activeThumbnail.offsetLeft + activeThumbnail.offsetWidth / 2;
            const scrollPosition = thumbnailOffset - thumbnailsContainer.offsetWidth / 2;
            thumbnailsContainer.scrollLeft = scrollPosition;
        }
    }, [selectedImage]);

    return (
        <div className={styles.estelWrapper}>
            <div className={styles.selectedMedia}>
                {selectedImage.endsWith('.jpg') ? (
                    <img className={styles.selectedImg} src={selectedImage} alt="Selected" />
                ) : (
                    <video controls className={styles.selectedVideo}>
                        <source src={selectedImage} type="video/mp4" />
                        Your browser does not support video.
                        </video>
                )}
                <div className={styles.estelText}>
                    <p>Red videojgggggg gggggg ggggg g  ggggg   gggg  g   ggg g g ggggggggg  gggg ggg gggg gggg ggggg gggg    gggg g ggggggggggg</p> 
                </div>
            </div>
            <button className={`${styles.estelButton} ${styles.left}`} onClick={() => scrollThumbnails('left')}>&lt;</button>
            <div className={styles.thumbnailsWrapper}>
                <div className={styles.thumbnails} ref={thumbnailsContainerRef}>
                    {/* {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Thumbnail ${index}`}
                            onClick={() => handleThumbnailClick(image)}
                            className={`${styles.thumbnail} ${image === selectedImage ? styles.activeThumbnail : ''} ${image.endsWith('.mp4') ? styles.videoThumbnail : ''}`}
                        />
                    ))} */}
                    {images.map((image, index) => (
                        <div key={index} className={`${styles.thumbnailContainer} ${image === selectedImage ? styles.activeThumbnail : ''}`} onClick={() => handleThumbnailClick(image)}>
                            <img
                            src={image}
                            alt={`Thumbnail ${index}`}
                            />
                            {image.endsWith('.mp4') && <i className={`${styles.playIcon}`}></i>}
                        </div>
                        ))}
                </div>
            </div>
            <button className={`${styles.estelButton} ${styles.right}`} onClick={() => scrollThumbnails('right')}>&gt;</button>
        </div>
  );
}

export default EstelProject


