import React from 'react'
import Header from '../../components/Header/Header'
import Footer from './../../components/Footer/Footer'
import FullBody2 from './../../components/Classes/FullBody2/FullBody2'

const FullBody2Class = () => {
  return <>
    <Header />
    <FullBody2 />
    <Footer />
  </>
}

export default FullBody2Class