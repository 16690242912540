import React, { useState } from 'react';
import './LoginForm.css'
import api from '../../services/api'; // Или путь к вашему файлу api.js
// import AuthContext from '../AuthContext';

const LoginForm = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailDirty, setEmailDirty] = useState(false);
  // const { login } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post('https://us-central1-sdds-413014.cloudfunctions.net/api/login', {
        username,
        password
      });
      // console.log(event);
      // console.log(username, password);
      window.alert('Login successful!');
      onLoginSuccess(response.data); // Вызовите onLoginSuccess с данными ответа
      // sessionStorage.setItem('token', response.data.token); // Измените sessionStorage на localStorage
      setError('');
      // console.log(response)
      // login(response.data.token);
    } catch (error) {
      console.error('Login failed', error);
      setError('Incorrect username or password');
    }
  };

  const emailHandler = (e) => {
    setUsername(e.target.value)
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmailError("Email is not valid!")
    } else {
      setEmailError("")
    }
  }

  const blurHandler = (e) => {
    setEmailDirty(true)
  }

  return (
    <form className='formf' onSubmit={handleSubmit}>
      <div>
        <label>Username:</label>
        <input 
          type="text" 
          value={username} 
          onChange={e => emailHandler(e)} 
          onBlur={e => blurHandler(e)}
          placeholder="Please write your email"
        />
      </div>
      <div>
        <label>Password:</label>
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
      </div>
      {(emailDirty && emailError) && <p className="error">{emailError}</p>}
      {error && <p className="error">{error}</p>}
      <button type="submit">Log in</button>
    </form>
  );
};

export default LoginForm;
