import React from 'react'
import Header from '../../components/Header/Header'
import Footer from './../../components/Footer/Footer'
import Stretch2 from './../../components/Classes/Stretch2/Stretch2'

const Stretch2Class = () => {
  return <>
    <Header />
    <Stretch2 />
    <Footer />
  </>
}

export default Stretch2Class