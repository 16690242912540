import React, { useState } from 'react';
import axios from 'axios';


const RegisterForm = ({ onRegisterSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://us-central1-sdds-413014.cloudfunctions.net/api/register', {
        username,
        password
      });
      window.alert('Registration successful!');
      console.log('Registration successful', response.data);
      onRegisterSuccess(response.data); // Вызовите onRegisterSuccess с данными ответа
    } catch (error) {
      window.alert('Registration failed');
      console.error('Registration failed', error);
      // Обработайте ошибку регистрации
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Username:</label>
        <input 
          type="text" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
          placeholder="Enter your email"
        />
      </div>
      
      <div>
        <label>Password:</label>
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
      </div>
      <button type="submit">Register</button>
    </form>
  );
};

export default RegisterForm;
