// import AboutMe from "../../components/AboutMe/AboutMe"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import HomeWhatIs from "../../components/HomeWhatIs/HomeWhatIs"
import OurStudents from "../../components/OurStudents/OurStudents"
import StylesAndTrainers from "../../components/StylesAndTrainers/StylesAndTrainers"
// import TimeTable from "../../components/TimeTable/TimeTable"
import Letter from "../../components/Letter/Letter"

const WhatIsSDDS = () => {
  return (
    <>
        <Header />
        <HomeWhatIs />
        <Letter />
        {/* <AboutMe /> */}
        <StylesAndTrainers />
        {/* <TimeTable /> */}
        <OurStudents />
        <Footer />
    </>
  )
}

export default WhatIsSDDS