import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams, Navigate } from "react-router-dom";

const ReturnPage = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [updateAccessStatus, setUpdateAccessStatus] = useState(null);

  // Функция для обновления доступа к уроку
  const updateLessonAccess = useCallback(async () => {
    try {
      const response = await fetch('/api/update-lesson-access', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId }),
      });
      const data = await response.json();
      setUpdateAccessStatus(data.message);
    } catch (error) {
      console.error('Ошибка при обновлении доступа:', error);
      setUpdateAccessStatus('Ошибка при обновлении доступа');
    }
  }, [sessionId]);

  // Получение статуса сессии оплаты
  useEffect(() => {
    if (sessionId) {
      fetch(`/api/session-status?session_id=${sessionId}`)
        .then(res => res.json())
        .then(data => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);

          // Если статус оплаты успешный, обновляем доступ к уроку
          if (data.status === 'complete') {
            updateLessonAccess();
          }
        });
    }
  }, [sessionId, updateLessonAccess]);

  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.
          If you have any questions, please email <a href="mailto:mariyapaik3@gmail.com">mariyapaik3@gmail.com</a>.
        </p>
        {updateAccessStatus && <p>{updateAccessStatus}</p>}
      </section>
    );
  }

  return null; // Или отображение состояния загрузки/обработки
}

export default ReturnPage;