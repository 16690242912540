import React, { useState } from 'react';
import styles from './ResetPassword.module.scss'

const ResetPasswordPage = () => {
  const logo = 'https://i.ibb.co/fXWcWq2/logopng.png'
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("The passwords don't match");
      return;
    }
    // Здесь должен быть код для сброса пароля
    console.log('Password reset:', password);
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        {/* <h2 className={styles.h}>Сброс пароля</h2> */}
        <div className={styles.logo}>
            <img className={styles.logoImg} src={logo} alt="Логотип" /> {/* Укажите путь к вашему логотипу */}
        </div>
        <form onSubmit={handleSubmit}>
          <label className={styles.label} htmlFor="password">New password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
          />

          <label htmlFor="confirmPassword">Confirm password:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className={styles.input}
          />

          {error && <div className={styles.error}>{error}</div>}

          <button type="submit" className={styles.resetButton}>
            Reset password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
