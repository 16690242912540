import HomePage from "./screens/Home/Home";
import OurProjectsPage from "./screens/OurProjects/OurProjects";
import WhatIsSDDSPage from "./screens/WhatIsSDDS/WhatIsSDDS";
import BuyMerchPage from "./screens/Buymerch/BuyMerch";
import BookClassesPage from "./screens/BookClasses/BookClasses"
import ReserveLessonPage from "./screens/ReserveLesson/ReserveLesson";
import BreatheClassPage from "./screens/BreatheClass/BreatheClass"
import StretchClass0Page from "./screens/StretchClass1/StretchClass1"
import ResetPasswordPage from "./screens/ResetPassword/ResetPassword";
import CheckoutPage from "./screens/CheckOutPage/CheckOutPage";
import ReturnPage from "./screens/ReturnPage/ReturnPage";
import Yoga1Page from "./screens/Yoga1Class/Yoga1Class";
import FullBody1Page from "./screens/FullBody1Class/FullBody1Class";
import FullBody2Page from "./screens/FullBody2Class/FullBody2Class";
import Stretch2Page from "./screens/Stretch2Class/Stretch2Class";
import Booty1Page from "./screens/Booty1Class/Booty1Class";

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from "./components/AuthContext";

// import ProtectedRoute from "./components/ProtectedRoute";

// const isAuthenticated = () => {
//   return !!localStorage.getItem('token');
// };

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/our-projects" element={<OurProjectsPage />} />
        <Route path="/what-is-sdds" element={<WhatIsSDDSPage />} />
        <Route path="/buy-merch" element={<BuyMerchPage />} />
        <Route path="/book-classes" element={<BookClassesPage />} />
        <Route path="/reserve-lesson" element={<ReserveLessonPage />} />
        <Route path="/breathe" element={ <BreatheClassPage /> } />
        <Route path="/stretch1" element={ <StretchClass0Page /> } />
        <Route path="/yoga1" element={ <Yoga1Page /> } />
        <Route path="/fullBody1" element={ <FullBody1Page /> } />
        <Route path="/stretch2" element={ <Stretch2Page /> } />
        <Route path="/fullBody2" element={ <FullBody2Page /> } />
        <Route path="/booty1" element={ <Booty1Page /> } />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/return" element={<ReturnPage />} />
      </Routes>
    </AuthProvider>
  )
}

export default App;
